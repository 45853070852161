const getItemFromLocalStorage = <T = unknown>(key: string): T | null => {
  const raw = localStorage.getItem(key);

  if (!raw) {
    return null;
  }

  try {
    return JSON.parse(raw);
  } catch {
    localStorage.removeItem(key);
    return null;
  }
};

export default getItemFromLocalStorage;
