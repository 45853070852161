import React from 'react';
import { TableauVizSingle } from '../reports/TableauVizSingle';
import { TABLEAU_URL } from 'common/constants';

export function NpsReportTab() {
  return (
    <TableauVizSingle
      tableauReportUrl={`${TABLEAU_URL}/views/DataPortalNPSReport/Dashboard10`}
      reportTitle={'NPS Report'}
    />
  );
}
