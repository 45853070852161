import React from 'react';
import { TableauVizSingle } from '../reports/TableauVizSingle';
import { REPORT_PATH_OVERVIEW, TABLEAU_URL } from 'common/constants';
import { useTranslation } from 'react-i18next';

export function OverviewTab() {
  const { t } = useTranslation();
  return (
    <TableauVizSingle tableauReportUrl={`${TABLEAU_URL}${REPORT_PATH_OVERVIEW}`} reportTitle={t('menu.overview')} />
  );
}
