import {styled} from '@mui/system';
import {Sidebar} from "../../components/sidebar/Sidebar";
import {Outlet} from "react-router-dom";

export default function DataOverviewLayout() {

  return (
    <Layout>
      <Sidebar/>
      <Outlet/>
    </Layout>
  );
}

const Layout = styled('div')`
  overflow: hidden;
  display: flex;
`;
