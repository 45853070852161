declare global {
  interface Window {
    _env_: {
      REACT_APP_API_BASE_URL?: string;
      REACT_APP_KEYCLOAK_URL?: string;
      REACT_APP_KEYCLOAK_REALM?: string;
      REACT_APP_KEYCLOAK_CLIENT_ID?: string;
      REACT_APP_USER_MANAGEMENT_WEB_API_URL?: string;
      REACT_APP_ENV_LABEL?: string;
      REACT_APP_TABLEAU_URL?: string;
      REACT_APP_REPORT_PATH_OVERVIEW?: string;
      REACT_APP_REPORT_PATH_MEDIA_OVERVIEW?: string;
      REACT_APP_REPORT_PATH_MEDIA_DEEP_DIVE?: string;
      REACT_APP_REPORT_PATH_ADS?: string;
      REACT_APP_REPORT_PATH_ADS_PERFORMANCE?: string;
      REACT_APP_REPORT_PATH_NPS?: string;
      REACT_APP_REPORT_PATH_INSEAT_DISPLAY_USAGE?: string;
    };
  }
}

const API_BASE_URL: string =
  (window._env_ ? window._env_.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_BASE_URL) || '';

const KEYCLOAK_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_URL
  : process.env.REACT_APP_KEYCLOAK_URL;
const KEYCLOAK_REALM: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_REALM
  : process.env.REACT_APP_KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID: string | undefined = window._env_
  ? window._env_.REACT_APP_KEYCLOAK_CLIENT_ID
  : process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
const USER_MANAGEMENT_WEB_API_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_USER_MANAGEMENT_WEB_API_URL
  : process.env.REACT_APP_USER_MANAGEMENT_WEB_API_URL;
const ENV_LABEL: string | undefined = window._env_ ? window._env_.REACT_APP_ENV_LABEL : process.env.REACT_APP_ENV_LABEL;
const TABLEAU_URL: string | undefined = window._env_
  ? window._env_.REACT_APP_TABLEAU_URL
  : process.env.REACT_APP_TABLEAU_URL;

const REPORT_PATH_OVERVIEW: string | undefined = window._env_
  ? window._env_.REACT_APP_REPORT_PATH_OVERVIEW
  : process.env.REACT_APP_REPORT_PATH_OVERVIEW;

const REPORT_PATH_MEDIA_OVERVIEW: string | undefined = window._env_
  ? window._env_.REACT_APP_REPORT_PATH_MEDIA_OVERVIEW
  : process.env.REACT_APP_REPORT_PATH_MEDIA_OVERVIEW;

const REPORT_PATH_MEDIA_DEEP_DIVE: string | undefined = window._env_
  ? window._env_.REACT_APP_REPORT_PATH_MEDIA_DEEP_DIVE
  : process.env.REACT_APP_REPORT_PATH_MEDIA_DEEP_DIVE;

const REPORT_PATH_ADS: string | undefined = window._env_
  ? window._env_.REACT_APP_REPORT_PATH_ADS
  : process.env.REACT_APP_REPORT_PATH_ADS;

const REPORT_PATH_ADS_PERFORMANCE: string | undefined = window._env_
  ? window._env_.REACT_APP_REPORT_PATH_ADS_PERFORMANCE
  : process.env.REACT_APP_REPORT_PATH_ADS_PERFORMANCE;

const REPORT_PATH_NPS: string | undefined = window._env_
  ? window._env_.REACT_APP_REPORT_PATH_NPS
  : process.env.REACT_APP_REPORT_PATH_NPS;

const REPORT_PATH_INSEAT_DISPLAY_USAGE: string | undefined = window._env_
  ? window._env_.REACT_APP_REPORT_PATH_INSEAT_DISPLAY_USAGE
  : process.env.REACT_APP_REPORT_PATH_INSEAT_DISPLAY_USAGE;

export {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  API_BASE_URL,
  USER_MANAGEMENT_WEB_API_URL,
  ENV_LABEL,
  TABLEAU_URL,
  REPORT_PATH_OVERVIEW,
  REPORT_PATH_MEDIA_OVERVIEW,
  REPORT_PATH_MEDIA_DEEP_DIVE,
  REPORT_PATH_ADS,
  REPORT_PATH_ADS_PERFORMANCE,
  REPORT_PATH_NPS,
  REPORT_PATH_INSEAT_DISPLAY_USAGE,
};
export const SELECTED_AIRLINE_LOCAL_STORAGE_KEY = 'DATA-PORTAL-SELECTED_AIRLINE';
