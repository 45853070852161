import {CenteredContentTemplate, Label, PageContent, PageTemplate} from '@control-tower/aerq-ui-library';
import {Box} from "@mui/material";
import useAirlineSelector from "../../common/hooks/useAirlineSelector";
import {AirlineLogo, AirlineSelectorItem} from "../../components/airline-selector/AirlineSelectorItem";
import {AirlineName} from "../../components/airline-selector/AirlineName";
import {AirlineMeta} from "../../common/types";

export function AirlineSelector() {

  const {isLoading, airlines, selectAirline} = useAirlineSelector();

  if (isLoading) return null;

  return (
    <PageTemplate>
      <PageContent>
        <CenteredContentTemplate>
          <Label fontSize='XLarge' lineHeight='28px' type='common' color='black' variant='body1'>
            Select an airline
          </Label>
          <Box margin={'1rem'}>
            {airlines ?
              airlines.map((airline: AirlineMeta) => {
                return (
                  <AirlineSelectorItem
                    key={airline.id}
                    onClick={() => {
                      selectAirline(airline)
                    }}>
                    {airline.logoUrl && <AirlineLogo src={airline.logoUrl} alt={airline.name} />}
                    <AirlineName
                      fontSize='Small'
                      lineHeight='19px'
                      type='common'
                      fontWeight='Medium'
                      color='black'
                      variant='body1'
                      hasLogo={!!airline.logoUrl}
                    >
                      {airline.name}
                    </AirlineName>
                  </AirlineSelectorItem>
                )
              }) : "No airlines have been configured"}
          </Box>
        </CenteredContentTemplate>
      </PageContent>
    </PageTemplate>
  )
}