import { Box, Typography, useTheme } from '@mui/material';
import getItemFromLocalStorage from '../../utils/getObjectFromLocalStorage';
import { SELECTED_AIRLINE_LOCAL_STORAGE_KEY } from '../../common/constants';
import { AirlineMeta } from '../../common/types';
import { AirlineLogo } from '../airline-selector/AirlineSelectorItem';
import { useTranslation } from 'react-i18next';

export function SelectedAirlineBox() {
  const airline = getItemFromLocalStorage(SELECTED_AIRLINE_LOCAL_STORAGE_KEY) as AirlineMeta;

  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        margin='1rem 1rem 0 1rem'
        borderRadius={2}
        border={`1px solid ${theme.palette.grey[200]}`}
        height={61}
      >
        {airline.logoUrl && <AirlineLogo src={airline.logoUrl} alt={airline.name} />}
        <Typography ml={'.5rem'} fontSize={'medium'}>
          {airline ? airline.name : (t('label_no_airline_selected') as string)}
        </Typography>
      </Box>
    </>
  );
}
