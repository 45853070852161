import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const ReportContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'calc(100% - 284px)',
  maxWidth: '90rem',
  flexDirection: 'column',
  padding: '2rem 1rem',
  margin: '0 auto',
  marginLeft: 284,
  marginTop: 71,
}));

export const ReportBox = styled(Box)(({ theme }) => ({
  '& iframe': {
    margin: 'auto',
  },
}));
