import {PropsWithChildren} from "react";
import {Box} from "@mui/material";

interface IProps extends PropsWithChildren {
  index: number,
  value: number
}
export function ReportTabPanel(props: IProps) {

  const {index, value, children} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  )
}