import React from 'react';
import { TableauVizMultiTabs } from '../reports/TableauVizMultiTabs';
import { TABLEAU_URL } from 'common/constants';

export function AdsReportTab() {
  return (
    <TableauVizMultiTabs
      actualRoutePath=''
      reportData={[
        {
          reportTitle: 'Ads Report',
          reportUrl: `${TABLEAU_URL}/views/DataPortalAdsReport/DataPortalReportActiveCampaignOverview`,
        },
        {
          reportTitle: 'Performance Analysis',
          reportUrl: `${TABLEAU_URL}views/DataPortalAdsReport/DataPortalReportRoutePerformanceAnalysis`,
        },
      ]}
    />
  );
}
