import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as ExportIcon } from 'assets/svg/upload.svg';
import { ExportButton } from './ExportButton';
import { ReportTabPanel } from './ReportTabPanel';
import { ReportTabs } from './ReportTabs';
import { ReportTabButton } from './ReportTabButton';
import { ReportContainer } from './ReportContainer';
import { TableauMultiTab } from './TableauMultiTab';
import { ExportEventType } from './exportEvent';
import { useTranslation } from 'react-i18next';
import useNavigate from 'common/hooks/useNavigate';

const titleStyles = {
  fontSize: '2rem',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.0625rem',
};

export type TabConfig = {
  reportTitle: string;
  reportUrl: string;
  routerPath?: string;
};

interface IProps {
  actualRoutePath: string;
  reportData: TabConfig[];
}

const tabs = ['overview', 'deep-dive'];
export function TableauVizMultiTabs(props: IProps) {
  const { reportData, actualRoutePath } = props;

  const navigateTo = useNavigate();

  const handleChange = (tabPath: string) => {
    navigateTo('push', tabPath || '');
  };

  function fireExportEvent(event: ExportEventType) {
    window.dispatchEvent(new CustomEvent(event));
  }

  const { t } = useTranslation();
  const pageTitle = actualRoutePath === 'overview' ? t('menu.media_overview') : t('menu.media_deep_dive');

  return (
    <ReportContainer>
      <Box display={'flex'} sx={{ justifyContent: 'space-between', marginBottom: '4rem' }}>
        <Typography sx={titleStyles}>{pageTitle}</Typography>
        <Stack spacing={2} direction={'row'} className={'test-export-buttons'}>
          <ExportButton
            label={t('btn_export_pdf')}
            callback={() => {
              fireExportEvent(ExportEventType.exportToPdf);
            }}
            icon={<ExportIcon />}
          />
          <ExportButton
            label={t('btn_export_csv')}
            icon={<ExportIcon />}
            callback={() => {
              fireExportEvent(ExportEventType.exportToCsv);
            }}
          />
          <ExportButton
            label={t('btn_export_excel')}
            icon={<ExportIcon />}
            callback={() => {
              fireExportEvent(ExportEventType.exportToExcel);
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <ReportTabs value={tabs.indexOf(actualRoutePath)} aria-label='tabs'>
          {reportData.map((report, key) => {
            return (
              <ReportTabButton label={report.reportTitle} path={report.routerPath} key={key} onClick={handleChange} />
            );
          })}
        </ReportTabs>
      </Box>
      {reportData.map((report, key) => {
        return (
          <ReportTabPanel value={tabs.indexOf(actualRoutePath)} index={key} key={key}>
            <TableauMultiTab reportTitle={report.reportTitle} reportUrl={report.reportUrl} />
          </ReportTabPanel>
        );
      })}
    </ReportContainer>
  );
}
