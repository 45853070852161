import {Button} from "@mui/material";
import React, {ReactNode} from "react";

interface IProps {
  label: string,
  callback?: () => void,
  icon: ReactNode
}
export function ExportButton(props: IProps) {

  const {label, callback, icon} = props;

  return (
    <Button
      sx={{
        border: "1px solid #E0E0E0",
        padding: '.75rem',
        maxHeight: '2.75rem'
      }}
      color="inherit"
      onClick={callback}
      startIcon={icon}>
      {label}
    </Button>
  )

}