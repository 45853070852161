import {styled} from "@mui/system";

export const AirlineSelectorItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.white,
  border: `1px solid ${theme.background.gray}`,
  borderRadius: 4,
  padding: '24px 20px',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',

  boxShadow: 'none',
  transition: 'box-shadow 100ms ease-in',

  '&:hover, &:active': {
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08)',
  },
}));

export const AirlineLogo = styled('img')`
  width: 48px;
  height: 48px;
`;