import {useEffect} from "react";

export enum ExportEventType {
  exportToPdf = 'exportToPdf',
  exportToCsv = 'exportToCsv',
  exportToExcel = 'exportToExcel'
}

export function useExportEventListener(eventListenerKey: ExportEventType, callback: () => void) {
  return useEffect(() => {
    window.addEventListener(eventListenerKey, callback)

    return () => {
      window.removeEventListener(eventListenerKey, callback)
    }
  }, [callback, eventListenerKey])
}