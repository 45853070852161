import React from 'react';
import { TableauVizMultiTabs } from '../reports/TableauVizMultiTabs';
import { REPORT_PATH_MEDIA_DEEP_DIVE, REPORT_PATH_MEDIA_OVERVIEW, TABLEAU_URL } from 'common/constants';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export function ContentTab() {
  const { t } = useTranslation();

  const { subpath } = useParams();

  return (
    <TableauVizMultiTabs
      actualRoutePath={subpath as string}
      reportData={[
        {
          reportTitle: t('menu.media_overview'),
          reportUrl: `${TABLEAU_URL}${REPORT_PATH_MEDIA_OVERVIEW}`,
          routerPath: '/main/media/overview',
        },
        {
          reportTitle: t('menu.media_deep_dive'),
          reportUrl: `${TABLEAU_URL}${REPORT_PATH_MEDIA_DEEP_DIVE}`,
          routerPath: '/main/media/deep-dive',
        },
      ]}
    />
  );
}
