import React from 'react';
import { styled } from '@mui/material/styles';
import { Tab } from '@mui/material';

interface IProps {
  label: string;
  path?: string;
  onClick: (tabPath: string) => void;
}

export const ReportTabButton = styled((props: IProps) => (
  <Tab
    disableRipple
    {...props}
    onClick={() => {
      if (props.onClick) props.onClick(props.path || '');
    }}
  />
))(() => ({
  textTransform: 'none',
  color: 'rgba(0, 0, 0, 0.4)',
  '&.Mui-selected': {
    color: '#000000',
  },
}));
