import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { InferProps, Validator, Requireable } from 'prop-types';
import { NavigationSidebar, NavigationSidebarItem, NavigationSidebarSection } from '@control-tower/aerq-ui-library';
import { SelectedAirlineBox } from './SelectedAirlineBox';
import { useLocation } from 'react-router-dom';
import useNavigate from 'common/hooks/useNavigate';

const SidebarBox = styled(Box)(() => ({
  display: 'flex',
  minWidth: '16.5rem',
  flexDirection: 'column',
  borderRight: 'thin solid #f5f5f5',
  position: 'fixed',
  marginTop: 71,
}));

export function Sidebar() {
  const location = useLocation();
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  return (
    <SidebarBox>
      <SelectedAirlineBox />
      <NavigationSidebar
        width={264}
        location={
          location as InferProps<{
            hash: Validator<string>;
            pathname: Validator<string>;
            search: Validator<string>;
            state: Requireable<NonNullable<string | number | boolean | object>>;
          }>
        }
        navigateTo={navigateTo}
      >
        <NavigationSidebarSection>
          <NavigationSidebarItem
            link={{ path: '/main/overview', external: false }}
            label={t('menu.overview') as string}
          />
          <NavigationSidebarItem link={{ path: '/main/media', external: false }} label={t('menu.media') as string} />
        </NavigationSidebarSection>
      </NavigationSidebar>

      {/* <SelectedAirlineBox/>
        <Divider sx={{mb: '1rem'}}/>
        <Stack spacing={2}>
          <SidebarLinkItem to={'overview'} label={'Overview'}/>
          <SidebarLinkItem to={'media'} label={'Media'}/>
          <SidebarDropdown label={'Survey'}>
            <SidebarLinkItem to={'onboarding-survey'} label={'Onboarding Survey'}/>
            <SidebarLinkItem to={'onboarding-survey'} label={'Full-blown Survey'}/>
          </SidebarDropdown>
          <SidebarLinkItem to={'nps'} label={'NPS'}/>
          <SidebarLinkItem to={'ads'} label={'Advertisement'}/>
          <SidebarLinkItem to={'isd-usage'} label={'In-Seat Display Usage'}/>
        </Stack> */}
    </SidebarBox>
  );
}
