import getItemFromLocalStorage from "../../utils/getObjectFromLocalStorage";
import {SELECTED_AIRLINE_LOCAL_STORAGE_KEY} from "../../common/constants";
import {Navigate, Outlet} from "react-router-dom";

export function AirlineSelectorGuard() {
  const airline = getItemFromLocalStorage(SELECTED_AIRLINE_LOCAL_STORAGE_KEY);

  if(!airline) {
    return <Navigate to={'/airline-selector'} replace/>
  }

  return <Outlet/>;
}