import React, {useEffect, useState} from "react";
import {ReportBox} from "./ReportContainer";
import {ExportEventType, useExportEventListener} from "./exportEvent";

// @ts-ignore
const {tableau} = window;

interface IProps {
  reportTitle: string,
  reportUrl: string
}
export function TableauMultiTab(props: IProps) {

  const {reportTitle, reportUrl} = props;
  const [viz, setViz] = useState(null);

  function initViz() {

    const options = {
      device: 'desktop'
    }

    let containerDiv = document.getElementById(reportTitle);
    setViz(new tableau.Viz(containerDiv, reportUrl, options));
  }

  useExportEventListener(ExportEventType.exportToPdf, exportToPDF)
  useExportEventListener(ExportEventType.exportToCsv, exportToCsv)
  useExportEventListener(ExportEventType.exportToExcel, exportToXlsx)

  function exportToPDF() {
    if (!viz) return;
    // @ts-ignore
    viz.showExportPDFDialog();
  }

  function exportToCsv() {
    // @ts-ignore
    viz.showExportCrossTabDialog();
  }

  function exportToXlsx() {
    // @ts-ignore
    viz.exportCrossTabToExcel();
  }

  useEffect(initViz, [reportTitle, reportUrl]);

  return (
    <ReportBox id={reportTitle}>
    </ReportBox>
  )
}