import Header from 'views/header/Header';
import { styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ErrorPage from '../views/error/ErrorPage';
import { useAPIErrors } from 'state/error/hooks';

const PageLayout = (props: object) => {
  const apiErrors = useAPIErrors();

  return (
    <Layout {...props}>
      <Header />
      {apiErrors && <ErrorPage />}
      {!apiErrors && <Outlet />}
    </Layout>
  );
};

const Layout = styled('div')`
  min-height: 100vh;
`;

export default PageLayout;
