import { useCallback, useEffect } from 'react';
import axios from 'services/axios';
import type { AxiosRequestConfig } from 'axios';
import { Routes, Route, useLocation, Link, Navigate, Outlet } from 'react-router-dom';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import {
  AERQNavigation,
  ProfileChangedFlyout,
  DemoModeFlyout,
  OrganizationSelector,
} from '@control-tower/aerq-navigation-library';
import { usePreferencesState } from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/context';
import { hideLoadingOverlay, showLoadingOverlay } from 'utils/toggleLoadingOverlay';
import { AuthorizationError } from '@control-tower/aerq-navigation-library';
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL, USER_MANAGEMENT_WEB_API_URL } from 'common/constants';
import { setupAuthorization } from 'services';
import useNavigate from 'common/hooks/useNavigate';
import i18n from 'i18n';
import Messages from './Messages';
import { SnackbarProvider } from 'notistack';
import { addMessage } from 'state/message/actions';
import PageLayout from '../components/PageLayout';
import DataOverviewLayout from './overview/DataOverviewLayout';
import { setupMessageHook } from '../services/interceptors/messages';
import { AirlineSelector } from './airline-selector/AirlineSelector';
import { AirlineSelectorGuard } from '../services/guards/AirlineSelectorGuard';
import { KeycloakInstance } from 'keycloak-js';
import { OverviewTab } from '../components/tableau/OverviewTab';
import { ContentTab } from '../components/tableau/ContentTab';
import { NpsReportTab } from '../components/tableau/NpsReportTab';
import { AdsReportTab } from '../components/tableau/AdsReportTab';
import { GeneralIsdUsageTab } from '../components/tableau/MediaWidgetReportTab';

const configAxios = (lang: string) => {
  const setHeader = (config: AxiosRequestConfig) => {
    return { ...config, headers: { ...config.headers, 'Accept-Language': lang } };
  };

  axios.interceptors.request.use(setHeader);
};

export default function App() {
  const translation = useTranslation();

  useEffect(() => {
    // setup service interceptor
    setupMessageHook(addMessage, translation);
    showLoadingOverlay();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleAERQNavigationError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      hideLoadingOverlay();

      if (error instanceof AuthorizationError) {
        // TODO: messagesDispatch(showErrorDialog(401, [{ code: 'NO_PERMISSION' }]));
        console.log('Nav lib error ', 'NO_PERMISSION');
        return;
      }

      if (error.response) {
        const { data, status } = error.response;
        console.log('Nav lib error ', status, data.messages);
        // TODO: messagesDispatch(showErrorDialog(status, data.messages));
        return;
      }

      if (error.request) {
        console.log('Nav lib error ', 404);
        // TODO: messagesDispatch(showErrorDialog(404));

        return;
      }

      console.log('Nav lib error');
      // TODO:  messagesDispatch(showErrorDialog());
    },
    []
  );

  const navigateTo = useNavigate();

  return (
    <AERQNavigation
      keycloakOptions={{
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM as string,
        clientId: KEYCLOAK_CLIENT_ID as string,
      }}
      onAfterSelectProfile={(orgInfo: { profileOrgId?: string }, keycloak: KeycloakInstance) => {
        setupAuthorization(orgInfo, keycloak);
      }}
      notificationServiceURL={''}
      userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL as string}
      ErrorComponent={undefined}
      onError={handleAERQNavigationError}
      navigateTo={navigateTo}
    >
      <ProfileChangedFlyout />
      <DemoModeFlyout />
      <MainContent />
    </AERQNavigation>
  );
}

const MainContent = () => {
  useEffect(() => {
    hideLoadingOverlay();
  }, []);

  const { preferences } = usePreferencesState();
  useEffect(() => {
    const lang = preferences.language.toLowerCase();
    i18n.changeLanguage(lang);
    localStorage.setItem('appLanguage', lang);

    configAxios(lang);
  }, [preferences.language]);

  const navigateTo = useNavigate();
  const location = useLocation();

  return (
    <AppContainer>
      <SnackbarProvider>
        <Messages />
        <Routes>
          <Route
            path='/organization-selector'
            element={
              <OrganizationSelector
                onAfterSelectProfile={(orgInfo, keycloak) => {
                  console.log('onAfterSelectProfile');
                  setupAuthorization(orgInfo, keycloak);
                }}
                userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL as string}
                notificationServiceURL=''
                clientId={KEYCLOAK_CLIENT_ID as string}
                navigateTo={navigateTo}
                location={location}
                Link={Link}
              />
            }
          />
          <Route path='/airline-selector' element={<AirlineSelector />} />
          <Route element={<AirlineSelectorGuard />}>
            <Route index element={<Navigate replace to='/main' />} />
            <Route element={<PageLayout />}>
              <Route path='/main' element={<DataOverviewLayout />}>
                <Route index element={<Navigate replace to='/main/overview' />} />
                <Route path='overview' element={<OverviewTab />} />
                <Route path='media' element={<Media />}>
                  <Route index element={<Navigate replace to='/main/media/overview' />} />
                  <Route path=':subpath' element={<ContentTab />} />
                </Route>

                <Route path={'nps'} element={<NpsReportTab />} />
                <Route path={'ads'} element={<AdsReportTab />} />
                <Route path={'isd-usage'} element={<GeneralIsdUsageTab />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </SnackbarProvider>
    </AppContainer>
  );
};

const Media = () => {
  return <Outlet />;
};

const AppContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.background.common};
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  flex-direction: column;

  & > div {
    flex-grow: 1;
  }
`;
