import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ExportIcon } from 'assets/svg/upload.svg';
import { ExportButton } from './ExportButton';
import { ReportBox, ReportContainer } from './ReportContainer';
import { useTranslation } from 'react-i18next';

// @ts-ignore
const { tableau } = window;

const titleStyles = {
  fontSize: '2rem',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.0625rem',
};

interface IProps {
  tableauReportUrl: string;
  reportTitle: string;
}

export function TableauVizSingle(props: IProps) {
  const { tableauReportUrl, reportTitle } = props;
  const [viz, setViz] = useState(null);

  const { t } = useTranslation();

  function initViz() {
    const options = {
      device: 'desktop',
    };

    let containerDiv = document.getElementById(reportTitle);
    setViz(new tableau.Viz(containerDiv, tableauReportUrl, options));
  }

  function exportToPDF() {
    if (!viz) return;
    // @ts-ignore
    viz.showExportPDFDialog();
  }

  function exportToCsv() {
    // @ts-ignore
    viz.showExportCrossTabDialog();
  }

  function exportToXlsx() {
    // @ts-ignore
    viz.exportCrossTabToExcel();
  }

  useEffect(initViz, [reportTitle, tableauReportUrl]);

  return (
    <ReportContainer>
      <Box display={'flex'} sx={{ justifyContent: 'space-between', marginBottom: '4rem' }}>
        <Typography sx={titleStyles}>{reportTitle}</Typography>
        <Stack spacing={2} direction={'row'} className={'test-export-buttons'}>
          <ExportButton label={t('btn_export_pdf')} callback={exportToPDF} icon={<ExportIcon />} />
          <ExportButton label={t('btn_export_csv')} callback={exportToCsv} icon={<ExportIcon />} />
          <ExportButton label={t('btn_export_excel')} callback={exportToXlsx} icon={<ExportIcon />} />
        </Stack>
      </Box>
      <ReportBox id={reportTitle}></ReportBox>
    </ReportContainer>
  );
}
