import { Header } from '@control-tower/aerq-navigation-library';
import { styled } from '@mui/material';
import { ENV_LABEL } from 'common/constants';
import useNavigate from 'common/hooks/useNavigate';
import { Link, useLocation } from 'react-router-dom';

const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 1000;
  background: white;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const HeaderController = (props: any) => {
  const location = useLocation();

  const navigateTo = useNavigate();

  return <StyledHeader Link={Link} fixed navigateTo={navigateTo} location={location} {...props} envLabel={ENV_LABEL} />;
};

export default HeaderController;
