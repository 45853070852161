import { styled } from '@mui/system';
import { Tabs } from '@mui/material';
import React from 'react';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange?: (event: React.SyntheticEvent, newValue: number) => void;
}

export const ReportTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    orientation={'horizontal'}
    TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    background: 'linear-gradient(90deg, #F59601 -5%, #CA0029 100%)',
  },
  '& .MuiTabs-flexContainer': {
    display: 'block',
  },
});
